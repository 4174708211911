import { httpClient } from "@/shared/services/http";

import { IAssessmentData } from "@/modules/assessments/scenes/CreateAssessment/assessment.interface";
import { ICandidateAssessmentsData } from "@/modules/assessments/assessments.types";
import { AxiosResponse } from "axios";

export interface GetAssessmentsParameters {
  take?: number;
  skip?: number;
  sortOptions?: { column: string | undefined; direction: string | undefined };
  search?: string;
  testIdsFilter?: number[];
}

export class AssessmentHttpService {
  static async getAllAssessments(clientId: number, options: GetAssessmentsParameters = {}) {
    const { skip, take, sortOptions, testIdsFilter } = options;

    const searchParams = new URLSearchParams();
    if (options?.search) {
      searchParams.set("search", options.search);
    }
    if (sortOptions?.column && sortOptions?.direction) {
      searchParams.set("sortBy", sortOptions.column);
      searchParams.set("sort", sortOptions.direction);
    }
    if (testIdsFilter) {
      searchParams.set("testIds", testIdsFilter.join(","));
    }
    if (typeof skip === "number" && typeof take === "number") {
      searchParams.set("take", String(take));
      searchParams.set("skip", String(skip));
    }

    const search = searchParams.toString();
    const url = `/api/clients/${clientId}/assessments` + (search ? `?${search}` : "");
    const { data: assessments } = await httpClient.get(url);

    return assessments;
  }

  static async getAssessmentByID(assessmentId: number) {
    const { data: assessment } = await httpClient.get(`/api/assessments/${assessmentId}`);
    return assessment;
  }

  static async removeAssessmentByID(clientId: number, assessmentId: number) {
    const { data: assessment } = await httpClient.delete(`/api/clients/${clientId}/assessments/${assessmentId}`);
    return assessment;
  }

  static async archiveAssessmentByID(clientId: number, assessmentId: number, options: { keepTeam: boolean }) {
    const { data: assessment } = await httpClient.post(
      `/api/clients/${clientId}/assessments/${assessmentId}/archive`,
      options,
    );
    return assessment;
  }

  static async createNewAssessment(clientId: number, postData: IAssessmentData) {
    return httpClient.post(`/api/clients/${clientId}/assessments`, postData);
  }

  static async updateAssessment(clientId: number, assessmentId: number, postData: IAssessmentData) {
    return httpClient.patch(`/api/clients/${clientId}/assessments/${assessmentId}`, postData);
  }

  static async getAllTextLocations() {
    const { data: textLocations } = await httpClient.get("/api/text-locations");
    return textLocations;
  }

  static async createInvite(assessmentId: number) {
    return httpClient.post(`/api/assessments/${assessmentId}/invites`);
  }

  static async inviteToAssessment(assessmentId: number, candidates: Blob) {
    const candidatesForm = new FormData();
    candidatesForm.append("file", candidates);

    const { data } = await httpClient.post(`/api/assessments/${assessmentId}/candidates/invites`, candidatesForm, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  }

  static async cloneAssessment(clientId: number, assessmentId: number) {
    return httpClient.post(`/api/clients/${clientId}/assessments/${assessmentId}/clone`);
  }

  static async fetchCandidateAssessmentsData(userId: number): Promise<ICandidateAssessmentsData> {
    const { data } = await httpClient.get(`api/assessments/user/${userId}`);

    return data;
  }

  static async exportAssessment(clientId: number, assessmentId: number): Promise<AxiosResponse<Blob>> {
    return httpClient.get(`api/clients/${clientId}/assessments/${assessmentId}/export-report/download`, {
      responseType: "blob",
    });
  }
}
