import React from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useInitializer } from "@/shared/hooks/initializer/initializer.hook";
import { PrivateAdminRoute } from "@/shared/components/PrivateRouter/PrivateAdminRoute";
import { DefaultAdminLayout } from "@/modules/layout/DefaultLayout/DefaultAdminLayout";
import { AdminSignIn } from "@/modules/auth/admin-sign-in";
import CandidateAssessmentSelfRegister from "@/modules/candidate/assessment/CandidateAssessment/CandidateAssessmentSelfRegister";
import { CandidateSignIn } from "@/modules/candidate/auth/candidate-sign-in";
import CandidateDashboard from "@/modules/candidate/CandidateDashboard/CandidateDashboard";
import CandidateAssessment from "@/modules/candidate/assessment/CandidateAssessment/CandidateAssessment";
import { MainPage } from "@/modules/main/MainPage/MainPage";
import { AdminDashboard } from "@/modules/admin/scenes/Admin/AdminDashboard";
import AdminUsers from "@/modules/admin/scenes/AdminUsers/AdminUsers";
import ClientTeam from "@/modules/admin/clientManagement/ClientTeam/ClientTeam";
import ClientTextsSettings from "@/modules/admin/clientManagement/ClientTextsSettings/ClientTextsSettings";
import CompetenciesClientSettings from "@/modules/admin/clientManagement/CompetenciesClientSettings/CompetenciesClientSettings";
import { TestCompetencies } from "@/modules/admin/testManagement/TestManagement/TestCompetencies";
import { TestNorms } from "@/modules/admin/testManagement/TestManagement/TestNorms/TestNorms";
import TestSettings from "@/modules/admin/testManagement/TestSettings/TestSettings";
import TestInstructions from "@/modules/admin/testManagement/TestInstructions/TestInstructions";
import Tests from "@/modules/admin/testManagement/Tests/Tests";
import { Clients } from "@/modules/client/scenes/Clients/Clients";
import CreateNewClient from "@/modules/client/scenes/CreateNewClient/CreateNewClient";
import InviteUser from "@/modules/client/scenes/InviteUser/InviteUser";
import { ClientSettings } from "@/modules/client/scenes/ClientSettings/ClientSettings";
import { UserSettings } from "@/modules/user-settings/scenes/UserSettings/UserSettings";
import NotificationSettings from "@/modules/user-settings/scenes/NotificationSettings/NotificationSettings";
import { Assessments } from "@/modules/assessments/scenes/Assessments/Assessments";
import { AddUserToAssessmentComponent } from "@/modules/assessments/scenes/AssessmentTeam/components/AddUserToAssessmentComponent/AddUserToAssessment.component";
import AssessmentReport from "@/modules/assessments/scenes/AssessmentReport/AssessmentReport";
import { CreateAssessment } from "@/modules/assessments/scenes/CreateAssessment/CreateAssessment";
import AddCandidate from "@/modules/assessments/scenes/AddCandidates/AddCandidate";
import { AssessmentEditHeader } from "@/modules/assessments/scenes/AssesmentEditHeader/AssessmentEditHeader";
import { AssessmentSelfRegister } from "@/modules/assessments/scenes/AssesmentSelfRegister/AssessmentSelfRegister";
import RescoreAssessment from "@/modules/assessments/scenes/RescoreAssessment/RescoreAssessment";
import NotFound from "@/modules/main/NotFound/NotFound";
import Forbidden from "@/modules/main/Forbidden/Forbidden";
import ServerFail from "@/modules/main/ServerFail/ServerFail";
import CandidateUserAssessments from "@/modules/candidate/CandidateUserAssessments/CandidateUserAssessments";
import CandidateLayout from "@/modules/layout/CandidateLayout/CandidateLayout";
import { SharedReport } from "@/shared/components/assessment/report/SharedReport/SharedReport";
import { PrivateCandidateRoute } from "@/shared/components/PrivateRouter/PrivateCandidateRoute";
import { PrivateClientRoute } from "@/shared/components/PrivateRouter/PrivateClientRoute";
import { useAdminInitializer } from "@/shared/hooks/initializer/admin-initializer.hook";
import { useGetAllRolesHook } from "@/shared/hooks/use-get-all-roles.hook";
import { AdminInitLogin } from "./AdminInitLogin";
import { CandidateInitLogin } from "./CandidateInitLogin";
import {
  ADMIN_ROUTE,
  ADMIN_USERS_ROUTE,
  ASSESSMENT_EDIT_INVITES_ROUTE,
  ASSESSMENT_ID_ROUTE,
  ASSESSMENT_INVITE_RESCORE_ROUTE,
  ASSESSMENT_INVITE_ROUTE,
  ASSESSMENT_ROUTE,
  ASSESSMENT_TAB_TYPE_ROUTE,
  CANDIDATE_ASSESSMENTS_ROUTE,
  CANDIDATE_ROUTE,
  CLIENT_COMPETENCIES_ROUTE,
  CLIENT_ID_ROUTE,
  CLIENT_ROUTE,
  USER_ROUTE,
  CLIENT_TEXTS_ROUTE,
  COMPETENCIES_ROUTE,
  FORBIDDEN_ROUTE,
  INIT_LOGIN_ROUTE,
  INVITES_ID_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  MAIN_PAGE_ROUTE,
  NEW_ROUTE,
  NOT_FOUND_ROUTE,
  NOTIFICATIONS_ROUTE,
  SELF_REGISTER_ROUTE,
  SERVER_FAIL_ROUTE,
  SETTINGS_ROUTE,
  SHARE_URL,
  TEST_ID_ROUTE,
  TEST_INSTRUCTIONS_ROUTE,
  TEST_NORMS_ROUTE,
  TESTS_ROUTE,
  USER_SETTINGS_ROUTE,
} from "./routes.constants";
import { GlobalProvider } from "./GlobalProvider";
import { AssessmentProvider } from "./AssessmentProvider";
import Logout from "@/modules/auth/Logout";
import ClientLayout from "@/modules/layout/ClientLayout/ClientLayout";

const AppRouter = () => {
  const { isLoading: isLoadingAuth, user, firebaseAuth, isSignedIn, isErroredState } = useInitializer();

  const {
    clientId,
    isClient,
    isAdmin,
    isLoading: isLoadingAdmin,
  } = useAdminInitializer({ user, isSignedIn, isLoadingAuth });

  useGetAllRolesHook(isSignedIn);

  const isLoading = isLoadingAuth && isLoadingAdmin;

  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            // GLOBAL CONTEXT
            <GlobalProvider
              user={user}
              clientId={Number(clientId)}
              isLoadingAuth={isLoadingAuth}
              firebaseUser={firebaseAuth.firebaseUser}
            >
              <Outlet />
            </GlobalProvider>
          }
        >
          {!isSignedIn && (
            <Route path="*" element={<NotFound isSignedIn={isSignedIn} isClient={isClient} isAdmin={isAdmin} />} />
          )}
          <Route
            element={
              <DefaultAdminLayout isLoading={isLoading}>
                <PrivateAdminRoute isAuthenticated={isSignedIn} isAdmin={isAdmin} />
              </DefaultAdminLayout>
            }
          >
            <Route path={ADMIN_ROUTE}>
              <Route index element={<AdminDashboard />} />
              <Route path={ASSESSMENT_ROUTE} element={<Assessments />} />
              <Route path={CLIENT_ROUTE} element={<Clients />} />
              <Route path={TESTS_ROUTE} element={<Tests />} />
              <Route path={ADMIN_USERS_ROUTE} element={<AdminUsers />} />
              <Route path={`${TESTS_ROUTE}/${TEST_ID_ROUTE}`} element={<TestSettings />}>
                <Route path={COMPETENCIES_ROUTE} element={<TestCompetencies />} />
                <Route path={TEST_NORMS_ROUTE} element={<TestNorms />} />
                <Route path={TEST_INSTRUCTIONS_ROUTE} element={<TestInstructions />} />
              </Route>
            </Route>
          </Route>

          <Route
            path={`${ASSESSMENT_ROUTE}/:assessmentId/${SELF_REGISTER_ROUTE}`}
            element={<AssessmentSelfRegister />}
          />

          <Route
            element={
              <DefaultAdminLayout isLoading={!isSignedIn ? isLoading : isLoadingAuth || isLoadingAdmin}>
                <PrivateClientRoute isAuthenticated={isSignedIn} isAdmin={isAdmin} isClient={isClient} />
              </DefaultAdminLayout>
            }
          >
            <Route path={`${CANDIDATE_ASSESSMENTS_ROUTE}/:userId`} element={<CandidateUserAssessments />} />
          </Route>

          <Route
            element={
              <DefaultAdminLayout isLoading={!isSignedIn ? isLoading : isLoadingAuth || isLoadingAdmin}>
                <PrivateClientRoute isAuthenticated={isSignedIn} isAdmin={isAdmin} isClient={isClient} />
              </DefaultAdminLayout>
            }
          >
            <Route path={CLIENT_ROUTE}>
              <Route index element={<Clients />} />
              <Route path={NEW_ROUTE} element={<CreateNewClient />} />
              <Route element={<ClientLayout />}>
                <Route path={`${CLIENT_ID_ROUTE}`} element={<Navigate to={`${SETTINGS_ROUTE}`} replace={true} />} />
                <Route path={`${CLIENT_ID_ROUTE}/${ASSESSMENT_ROUTE}`} element={<Assessments />} />
                <Route path={`${CLIENT_ID_ROUTE}/${ASSESSMENT_ROUTE}/${NEW_ROUTE}`} element={<CreateAssessment />} />
                <Route path={`${CLIENT_ID_ROUTE}/${SETTINGS_ROUTE}`} element={<ClientSettings />}>
                  <Route path={`${USER_ROUTE}`} element={<ClientTeam />} />
                  <Route path={`${CLIENT_COMPETENCIES_ROUTE}`} element={<CompetenciesClientSettings />} />
                  <Route path={`${CLIENT_TEXTS_ROUTE}`} element={<ClientTextsSettings />} />
                </Route>
                <Route
                  path={`${CLIENT_ID_ROUTE}/${SETTINGS_ROUTE}/${USER_ROUTE}/${NEW_ROUTE}`}
                  element={<InviteUser />}
                />

                <Route
                  path={`${CLIENT_ID_ROUTE}/${ASSESSMENT_ROUTE}/${ASSESSMENT_ID_ROUTE}`}
                  element={
                    // ASSESSMENT CONTEXT - needs assessment_id in params
                    <DefaultAdminLayout isLoading={!isSignedIn ? isLoading : isLoadingAuth || isLoadingAdmin}>
                      <AssessmentProvider>
                        <PrivateClientRoute isAuthenticated={isSignedIn} isAdmin={isAdmin} isClient={isClient} />
                      </AssessmentProvider>
                    </DefaultAdminLayout>
                  }
                >
                  <Route index element={<AssessmentEditHeader />} />
                  <Route path={`${USER_ROUTE}/`} element={<AddUserToAssessmentComponent />} />
                  <Route
                    path={`${ASSESSMENT_INVITE_ROUTE}/${ASSESSMENT_INVITE_RESCORE_ROUTE}`}
                    element={<RescoreAssessment />}
                  />
                  <Route path={`${ASSESSMENT_TAB_TYPE_ROUTE}/`} element={<AssessmentEditHeader />}>
                    <Route path={`${INVITES_ID_ROUTE}`} element={<AssessmentReport />} />
                  </Route>
                  <Route path={`${ASSESSMENT_EDIT_INVITES_ROUTE}/${NEW_ROUTE}`} element={<AddCandidate />} />
                </Route>
              </Route>

              <Route path={"*"} element={<Navigate replace to="/404" />} />
            </Route>

            <Route path={USER_SETTINGS_ROUTE} element={<UserSettings />}>
              <Route path={`${NOTIFICATIONS_ROUTE}`} element={<NotificationSettings />} />
            </Route>

            <Route path="*" element={<NotFound isSignedIn={isSignedIn} isClient={isClient} isAdmin={isAdmin} />} />
            <Route
              path={NOT_FOUND_ROUTE}
              element={<NotFound isSignedIn={isSignedIn} isClient={isClient} isAdmin={isAdmin} />}
            />
            <Route
              path={FORBIDDEN_ROUTE}
              element={<Forbidden isAdmin={isAdmin} isClient={isClient} isSignedIn={isSignedIn} />}
            />
          </Route>

          <Route
            element={
              <CandidateLayout isAuthenticated={isSignedIn} isLoading={isLoading}>
                <PrivateCandidateRoute isAuthenticated={isSignedIn} />
              </CandidateLayout>
            }
          >
            <Route path="*" element={<NotFound isSignedIn={isSignedIn} isClient={isClient} isAdmin={isAdmin} />} />
            <Route path={CANDIDATE_ROUTE}>
              <Route index element={<NotFound isSignedIn={isSignedIn} isAdmin={isAdmin} isClient={isClient} />} />
              <Route path="*" element={<NotFound isSignedIn={isSignedIn} isClient={isClient} isAdmin={isAdmin} />} />
              <Route path="invites" element={<CandidateDashboard />} />
            </Route>
          </Route>

          <Route
            element={
              <CandidateLayout isAuthenticated={isSignedIn} isLoading={isLoading}>
                <Outlet />
              </CandidateLayout>
            }
          >
            <Route path={CANDIDATE_ROUTE}>
              <Route index element={<NotFound isSignedIn={isSignedIn} isAdmin={isAdmin} isClient={isClient} />} />
              <Route path={LOGIN_ROUTE} element={<CandidateSignIn isSignedIn={isSignedIn} isLoading={isLoading} />} />
              <Route
                path={`${ASSESSMENT_ROUTE}/:assessmentId/${SELF_REGISTER_ROUTE}`}
                element={<CandidateAssessmentSelfRegister />}
              />
              <Route path="*" element={<NotFound isSignedIn={isSignedIn} isClient={isClient} isAdmin={isAdmin} />} />
              <Route
                path={NOT_FOUND_ROUTE}
                element={<NotFound isSignedIn={isSignedIn} isClient={isClient} isAdmin={isAdmin} />}
              />
              <Route
                path={FORBIDDEN_ROUTE}
                element={<Forbidden isAdmin={isAdmin} isClient={isClient} isSignedIn={isSignedIn} />}
              />
              <Route
                path="invites/:code"
                element={<CandidateAssessment firebaseLoading={firebaseAuth.isLoading} userId={user?.userId} />}
              />
            </Route>
          </Route>

          <Route path={`report/${SHARE_URL}`} element={<SharedReport firebaseLoading={firebaseAuth.isLoading} />} />

          <Route path="*" element={<NotFound isSignedIn={isSignedIn} isClient={isClient} isAdmin={isAdmin} />} />
          <Route
            path={INIT_LOGIN_ROUTE}
            element={
              <AdminInitLogin
                clientID={clientId}
                isAdmin={isAdmin}
                isClient={isClient}
                isLoading={isLoading}
                isErroredState={isErroredState}
              />
            }
          />
          <Route
            path={`${CANDIDATE_ROUTE}/${INIT_LOGIN_ROUTE}`}
            element={<CandidateInitLogin isErroredState={isErroredState} />}
          />
          <Route path={LOGIN_ROUTE} element={<AdminSignIn isSignedIn={isSignedIn} isLoading={isLoading} />} />
          <Route path={MAIN_PAGE_ROUTE} element={<MainPage />} />
          <Route
            path={`/${SERVER_FAIL_ROUTE}`}
            element={<ServerFail isSignedIn={isSignedIn} isClient={isClient} isAdmin={isAdmin} />}
          />
        </Route>
        <Route path={LOGOUT_ROUTE} element={<Logout />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
