import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";
import { UserContext } from "@/shared/contexts/user.context";
import { minToSec, secToMls } from "@/shared/utils/common.utils";
import { getRouteWithSearchParameter } from "@/shared/utils/route/route.utils";
import { IAssessmentInvite } from "@/shared/hooks/initializer/use-initialize-candidate.hook";
import { ITestAnswer, ITestAnswerTimes } from "@/shared/components/charts/types/chartTypes";
import { ITestChoice, QuestionTypes } from "@/shared/components/candidate/scenes/TestElement/TestElement";
import {
  ASSESSMENT_CODE_SEARCH,
  CANDIDATE_ROUTE,
  LOGIN_ROUTE,
  NOT_FOUND_ROUTE,
} from "@/routing/AppRouter/routes.constants";
import { ITest, ITestElement } from "@/modules/assessments/types/test.interface";
import { InvitesHttpService } from "@/modules/candidate/services/InvitesHttpService";
import { AssessmentInviteStatus } from "@/modules/assessments/scenes/AssessmentInvites/invites.constants";
import { IAssessment } from "@/modules/assessments/assessments.types";
import { DELTA_TIME_SEC } from "./useSections.hook";
import { useNavigationButtonsHook } from "./useNavigationButtons.hook";
import { getElementKeyIndex, getLastAnsweredTestElement } from "../getLastAnsweredElement";
import {
  getIfNextQuestionNewTest,
  getMissedAnswerQuestionsKeys,
  isAllQuestionsAnswered,
} from "../utils/candidate-assessment-view.utils";
import { getNextSectionIndex } from "../utils/section.utils";

export function useCurrentAnswerHook({
  test,
  invite,
  questionsList,
  assessment,
  answers,
  saveTestElementAnswer,
  currentPage,
  setCurrentPage,
  currentAnswers,
  setCurrentAnswers,
  sectionsTime,
  isTimedOutSection,
  currentSectionElement,
}: {
  test?: ITest;
  invite?: IAssessmentInvite;
  assessment?: IAssessment;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  currentAnswers: ITestChoice[];
  setCurrentAnswers: Dispatch<SetStateAction<ITestChoice[]>>;
  questionsList: ITestElement[][];
  answers: ITestAnswer[];
  saveTestElementAnswer: (
    currentAnswers: ITestChoice[],
    { inviteId, testId }: { inviteId: number; testId: number },
    options: { isLast?: boolean; isBack?: boolean },
  ) => Promise<ITestChoice[]>;
  isTimedOutSection: boolean;
  sectionsTime: ITestAnswerTimes;
  currentSectionElement?: ITestElement;
}) {
  const navigate = useNavigate();
  const { code } = useParams();
  const { pathname } = useLocation();

  const { user } = useContext(UserContext);

  const [isOpenedUnansweredModal, setIsOpenedUnansweredModal] = useState<boolean>(false);
  const [isOpenedConfirmationModal, setIsOpenedConfirmationModal] = useState<boolean>(false);

  const [isStartingLoading, setIsStartingLoading] = useState<boolean>(false);

  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);

  const { isAllowToGoBack, isAllowToGoNext } = useNavigationButtonsHook({
    test,
    currentPage,
    questionsList,
    currentAnswers,
  });

  const ifSectionHasTime =
    currentSectionElement?.config.key &&
    sectionsTime[currentSectionElement?.config.key] &&
    sectionsTime[currentSectionElement?.config.key].time;

  // save answers after the section timeout
  useEffect(() => {
    const startsSection = currentSectionElement?.config?.startsSection;
    const sectionKey = currentSectionElement?.config.key;

    if (!startsSection || !sectionKey || !sectionsTime[sectionKey]) {
      return;
    }

    const isAllowedToSubmitAnswer =
      startsSection.timeLimit &&
      minToSec(startsSection.timeLimit) <= sectionsTime[sectionKey].time + DELTA_TIME_SEC &&
      sectionsTime[sectionKey].lastUpdated + secToMls(DELTA_TIME_SEC) >= Date.now();

    if (invite?.inviteId && test?.testId && isAllowedToSubmitAnswer) {
      (async () => {
        await saveTestElementAnswer(
          currentAnswers,
          { inviteId: invite.inviteId, testId: test.testId },
          { isBack: true },
        );
      })();
    }
  }, [currentSectionElement?.config?.startsSection, ifSectionHasTime, invite?.inviteId, test?.testId]);

  const saveAnswers = async (
    {
      inviteId,
      testId,
      isLast = false,
      isBack = false,
    }: {
      inviteId: number;
      testId: number;
      isLast?: boolean;
      isBack?: boolean;
    },
    isConfirmed = false,
  ) => {
    if (!test?.testId) {
      return;
    }

    setIsLoadingButton(true);

    try {
      // isLast = finish test
      // when action is confirmed and
      //  when is the next question from the new test
      //  when current page equal last question in the list (the last page)
      if (!isTimedOutSection || isLast || (isConfirmed && !isTimedOutSection)) {
        await saveTestElementAnswer(currentAnswers, { inviteId, testId: test?.testId }, { isLast, isBack });
      }

      setCurrentPage((prevState) => {
        return isBack ? prevState : prevState + 1;
      });
    } finally {
      navigate(pathname.split("?")[0]);
      setIsLoadingButton(false);

      // TODO: use ref instead
      const upperElement = document.getElementById("first");
      if (upperElement) {
        upperElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  const handleChoiceSelect = (value: ITestChoice) => {
    const hasAnswer = currentAnswers.some((answer) => answer.elementId === value.elementId);
    if (!hasAnswer) {
      setCurrentAnswers((prevState) => [...prevState, value]);
    } else {
      const answersWithoutNew = currentAnswers.filter((answer) => answer.elementId !== value.elementId);
      setCurrentAnswers([...answersWithoutNew, value]);
    }
  };

  const goNextTest = () => {
    if (!test?.config.backButton || !invite || !test?.testId) {
      return;
    }

    (async () => {
      await saveTestElementAnswer(currentAnswers, { inviteId: invite.inviteId, testId: test.testId }, { isLast: true });

      const nextPageIndex = questionsList.findIndex((elements, index) => {
        if (index <= currentPage - 1) {
          return false;
        }

        return elements[0].testId !== test?.testId;
      });

      setCurrentPage(nextPageIndex + 1);
    })();
  };

  const finishAssessment = () => {
    if (!invite || !test?.testId) {
      return;
    }

    (async () => {
      await saveTestElementAnswer(currentAnswers, { inviteId: invite.inviteId, testId: test.testId }, { isLast: true });

      setCurrentPage(questionsList.length + 1);
    })();
  };
  const goNextSection = () => {
    if (!currentPage) {
      return;
    }

    const nextSectionIndex = getNextSectionIndex(questionsList, currentPage);

    goToElementIndex(nextSectionIndex);
  };

  const goBackToUnanswered = (toLastUnanswered = false) => {
    const missedKeys = getMissedAnswerQuestionsKeys(questionsList, answers, test?.testId);

    let missedKey = missedKeys.at(0);

    if (toLastUnanswered && assessment?.assessmentTests && invite?.testAnswers) {
      const lastAnsweredElement = getLastAnsweredTestElement(
        assessment.assessmentTests.find((assessmentTest) => assessmentTest.testId === test?.testId),
        answers.find((answer) => answer.testId === test?.testId),
      );

      if (lastAnsweredElement) {
        // +1 to move on the next page after the last answered
        missedKey = getElementKeyIndex(questionsList, lastAnsweredElement) + 1;
      }
    }

    if (!missedKeys.length) {
      missedKey = questionsList.length - 1;
    }

    goToElementIndex(missedKey);
  };

  const goToElementIndex = (elementIndex?: number) => {
    if (typeof elementIndex !== "number") {
      return;
    }

    const elementPage = elementIndex + 1;

    if (currentPage === elementPage || !questionsList[elementIndex]) return;

    const route = getRouteWithSearchParameter(
      pathname,
      ASSESSMENT_CODE_SEARCH,
      questionsList[elementIndex][0]?.config.key,
    );

    setCurrentPage(elementPage);

    navigate(route);
  };

  const handleBackClick = async () => {
    if (!test?.config.backButton || !invite || !test?.testId) {
      return;
    }
    setCurrentPage((prevState) => prevState - 1);

    await saveAnswers({ testId: test.testId, isBack: true, inviteId: invite.inviteId });
  };

  const handleNextClick = async (isConfirmed = false, isBack = false) => {
    if (!invite || !test?.testId || !questionsList) return;

    // when not allowed to go back and has unanswered questions on current page
    // 1. show confirmation modal
    // 2. continue / cancel
    if (
      !isConfirmed &&
      !test?.config.backButton &&
      !isAllQuestionsAnswered(currentAnswers, questionsList, currentPage)
    ) {
      return setIsOpenedConfirmationModal(true);
    }

    const ifNextQuestionNewTest = getIfNextQuestionNewTest(questionsList, currentPage);
    const missedKeys = getMissedAnswerQuestionsKeys(questionsList, answers, test.testId, false);

    const currentPageQuestions = questionsList[currentPage - 1].filter(
      ({ config }) => config.type !== QuestionTypes.Text,
    );
    const lastMissedKey = missedKeys.at(-1);
    const isLastMissedTestPage = lastMissedKey === currentPage - 1;
    // if last missed answers on the current page ->
    //   check that current answers equal to current page questions length (means that all questions answered)
    //   if yes (all questions answered), delete key from missing
    if (
      currentAnswers.filter((answer) => answer.choiceKey).length === currentPageQuestions.length &&
      isLastMissedTestPage
    ) {
      missedKeys.splice(-1, 1);
    }

    // when allow to go back and has unanswered questions on current test
    // 1. show confirmation modal
    // 2. continue (ignore) / confirm to go back to first unanswered
    if (ifNextQuestionNewTest && !isConfirmed && test?.config.backButton && missedKeys.length) {
      return setIsOpenedUnansweredModal(true);
    }

    if ((!missedKeys.length && !isTimedOutSection) || isLastMissedTestPage) {
      isConfirmed = true;
    }

    if (isLoadingButton) {
      return;
    }

    const isBackAllowed = isBack && test?.config.backButton;

    const isLast =
      (ifNextQuestionNewTest && isConfirmed) ||
      (questionsList.length === currentPage && (isConfirmed || isTimedOutSection));
    await saveAnswers(
      { isLast: isLast && !isBackAllowed, testId: test.testId, isBack: isBackAllowed, inviteId: invite.inviteId },
      isConfirmed,
    );
  };

  const handleSubmitClick = async () => {
    if (!user || !user.userId) {
      navigate(getRouteWithSearchParameter(`/${CANDIDATE_ROUTE}/${LOGIN_ROUTE}`, ASSESSMENT_CODE_SEARCH, String(code)));
    }

    if (invite && invite.status === AssessmentInviteStatus.InProgress && assessment?.assessmentTests) {
      goBackToUnanswered(!test?.config.backButton);
    } else {
      setIsStartingLoading(true);

      if (invite?.status === AssessmentInviteStatus.Rescinded) {
        navigate(`/${NOT_FOUND_ROUTE}`);

        return notification.error({
          message:
            "Sorry, this is no longer available. If you think this is a mistake, please reach out to the hiring manager.",
        });
      }

      if (invite?.inviteId && user?.userId) {
        try {
          invite?.inviteId && (await InvitesHttpService.startAssessment(invite?.inviteId));
        } catch (err: any) {
          if (err.response.data.message.includes(AssessmentInviteStatus.Rescinded)) {
            navigate(`/${NOT_FOUND_ROUTE}`);

            return notification.error({
              message:
                "Sorry, this is no longer available. If you think this is a mistake, please reach out to the hiring manager.",
            });
          } else {
            throw err;
          }
        }
      }

      setIsStartingLoading(false);
      setCurrentPage(1);
    }
  };

  return {
    handleBackClick,
    handleNextClick,
    handleSubmitClick,
    handleChoiceSelect,
    goNextTest,
    goNextSection,
    finishAssessment,
    goBackToUnanswered,
    isStartingLoading,
    isOpenedConfirmationModal,
    setIsOpenedConfirmationModal,
    isOpenedUnansweredModal,
    setIsOpenedUnansweredModal,
    isLoadingButton,
    isAllowToGoBack,
    isAllowToGoNext: isAllowToGoNext || isTimedOutSection,
  };
}
