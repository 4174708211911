import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Form } from "antd";
import classNames from "classnames";

import { selectClientRoles } from "@/shared/services/store/rolesStore";
import { useAssessmentHook } from "@/shared/hooks/assessments/useAssessment.hook";
import { useGetClientUsersHook } from "@/shared/hooks/users/use-get-client-users.hook";

import { CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { CustomInput, InputType } from "@/ui-components/commonComponents/Input/Input";
import { SEO } from "@/ui-components/commonComponents/SEO/SEO";

import { AssessmentAbilityContext } from "@/shared/contexts/ability.context";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";

import { Loading } from "@/shared/components/Loading/Loading";
import { ASSESSMENT_ROUTE, CLIENT_ROUTE, USER_ROUTE } from "@/routing/AppRouter/routes.constants";

import { ClientRoleEnum } from "@/modules/client/client-roles.interface";
import { AssessmentRoleEnum } from "@/modules/assessments/services/assessment-permissions-http.service";
import { getAssessmentRolesList } from "@/modules/assessments/scenes/AssessmentTeam/assessment-roles.utils";

import { useManageAssessmentUserHook } from "@/modules/assessments/hooks/use-manage-assessment-user.hook";
import { useGetAssessmentUsersPermissionsHook } from "@/modules/assessments/hooks/use-get-assessment-users-permissions.hook";

import style from "@/shared/components/AddNew/style.module.css";

import styles from "./add-user-to-assessment.styles.module.css";

export function getAddUserToAssessmentPageLink(clientId: number, assessmentId: number) {
  return `/${CLIENT_ROUTE}/${clientId}/${ASSESSMENT_ROUTE}/${assessmentId}/${USER_ROUTE}`;
}

export type AddUserToAssessmentComponentProps = {};

export function AddUserToAssessmentComponent({}: AddUserToAssessmentComponentProps) {
  const { assessment_id: assessmentId } = useParams<{
    assessment_id: string;
  }>();
  const clientRoles = useSelector(selectClientRoles);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  // TODO: Move to global state
  const { assessment, isLoading: isLoadingAssessment } = useAssessmentHook(Number(assessmentId));

  const { users: clientUsers, isLoading: isLoadingClientUsers } = useGetClientUsersHook(assessment?.clientId);
  const { assessmentPermissions, isLoading: isLoadingAssessmentPermissions } = useGetAssessmentUsersPermissionsHook(
    assessment?.assessmentId,
  );
  const { manageAssessmentUser, isLoading: isLoadingUserToAssessment } = useManageAssessmentUserHook();

  const navigateBack = () => {
    navigate(-1);
  };

  const addUserAndNavigateBack = async ({ user, role }: { user: string; role: AssessmentRoleEnum }) => {
    await manageAssessmentUser(Number(assessmentId), Number(user), role);
    navigateBack();
  };

  // check if not global admin & not client admin & have not existed assessment role
  const users = clientUsers
    .filter(({ userId, isAdmin, clientUsers }) => {
      const existedAssessmentPermissions = assessmentPermissions.find(
        (assessmentPermission) => assessmentPermission.userId === userId,
      );
      const clientUserRole = clientRoles.find(({ roleId }) => clientUsers && clientUsers[0].roleId === roleId);
      const isClientUserAdmin = clientUserRole && clientUserRole.name === ClientRoleEnum.ADMIN;

      return !existedAssessmentPermissions && !isClientUserAdmin && !isAdmin;
    })
    .map((user) => ({
      value: String(user.userId),
      name: user.fullName,
    }));

  const inputProps = [
    {
      name: "user",
      label: "User",
      errorText: "User",
      inputType: InputType.select,
      selectOptions: users,
      required: true,
    },
    {
      name: "role",
      label: "Assessment Role",
      errorText: "Assessment role",
      inputType: InputType.select,
      selectOptions: getAssessmentRolesList(),
      required: true,
    },
  ];

  const assessmentAbility = useContext(AssessmentAbilityContext);
  const canCreateAssessmentUser = assessmentAbility.can(Action.Create, Subject.ASSESSMENT_USER_PERMISSION);

  if (isLoadingAssessment || isLoadingUserToAssessment || isLoadingClientUsers || isLoadingAssessmentPermissions) {
    return <Loading />;
  }

  return (
    <div className={styles.background}>
      <SEO titleParams={[assessment?.name, "Add User"]} />
      <div className={styles.mainContainer}>
        <Form
          form={form}
          name="AssessmentUser"
          requiredMark={false}
          className={styles.formContainer}
          onFinish={addUserAndNavigateBack}
          initialValues={{ user: null, role: AssessmentRoleEnum.USER }}
        >
          {inputProps.map(({ name, label, required, errorText, selectOptions, ...input }) => (
            <Form.Item
              className={classNames(style.inputTitle, styles.userAssessmentInput)}
              key={name}
              name={name}
              label={label}
              rules={[
                {
                  type: "string",
                  required: required,
                  message: errorText,
                },
              ]}
            >
              <CustomInput
                verboseMode
                placeholder="Select..."
                className={styles.selectInput}
                selectOptions={selectOptions}
                inputType={input.inputType}
              />
            </Form.Item>
          ))}

          <div className={classNames(style.buttonContainer, styles.buttonsContainer)}>
            <CustomButton title="Add" isLoading={isLoadingUserToAssessment} disabled={!canCreateAssessmentUser} />

            <CustomButton
              title="Cancel"
              submitted={false}
              onClick={navigateBack}
              isLoading={isLoadingUserToAssessment}
            />
          </div>
        </Form>
      </div>
    </div>
  );
}
