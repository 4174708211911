import { ReactElement } from "react";
import cn from "classnames";

import { SEO } from "@/ui-components/commonComponents/SEO/SEO";

import logo from "@/assets/image/psp-logo.svg";

import style from "./style.module.css";

interface IErrorPage {
  isSignedIn: boolean;
  isAdmin: boolean;
  isClient: boolean;
  errorReason: string | null;
  children: ReactElement;
  errorCode?: number;
}

export default function ErrorPage({ isSignedIn, isAdmin, isClient, errorCode, errorReason, children }: IErrorPage) {
  return (
    <div className={cn(style.wrapper, { [style.pageWithHeaderWrapper]: isAdmin || isClient })}>
      <SEO titleParams={["Error"]} />
      <div className={style.logo}>{!isSignedIn && <img src={logo} alt="PSP Metrics" className={style.logoImage} />}</div>

      <div className={style.errorContainer}>
        <p className={style.title}>Error</p>
        <p className={style.reason}>{errorReason}</p>
        {children}
      </div>
    </div>
  );
}
