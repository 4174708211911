export const MAIN_PAGE_ROUTE: string = "/";
export const ADMIN_ROUTE: string = "admin";
export const ASSESSMENT_ROUTE: string = "assessments";
export const CLIENT_ROUTE: string = "clients";
export const TESTS_ROUTE: string = "tests";
export const ADMIN_USERS_ROUTE: string = "admin-users";
export const ASSESSMENT_INVITE_ROUTE = "invites";

export const NEW_ROUTE: string = "new";

export const CLIENT_ID_ROUTE: string = ":client_id";
export const ASSESSMENT_ID_ROUTE: string = ":assessment_id";
export const TEST_ID_ROUTE: string = ":test_id";
export const INVITES_ID_ROUTE: string = ":invite_id";
export const ASSESSMENT_TAB_TYPE_ROUTE: string = ":tab_type";
export const SHARE_URL: string = ":share_url";

export const USER_ROUTE: string = "team";
export const CLIENT_COMPETENCIES_ROUTE: string = "competencies";
export const CLIENT_TEXTS_ROUTE: string = "texts";
export const CANDIDATE_ROUTE: string = "candidate";
export const CANDIDATE_ASSESSMENTS_ROUTE: string = "candidate-assessments";

export const SETTINGS_ROUTE: string = "settings";

export const ASSESSMENT_EDIT_TESTS_ROUTE: string = "tests";
export const ASSESSMENT_EDIT_INVITES_ROUTE: string = "invites";
export const ASSESSMENT_EDIT_COMPARE_ROUTE: string = "compare";
export const ASSESSMENT_INVITE_RESCORE_ROUTE = "rescore";

export const USER_SETTINGS_ROUTE = "user-settings";
export const NOTIFICATIONS_ROUTE = "notifications";

export const TEST_NORMS_ROUTE: string = "norms";
export const TEST_INSTRUCTIONS_ROUTE: string = "instructions";

export const LOGIN_ROUTE: string = "login";
export const INIT_LOGIN_ROUTE: string = "init-login";
export const LOGOUT_ROUTE = "logout";

export const COMPETENCIES_ROUTE: string = "competencies";

export const NOT_FOUND_ROUTE: string = "404";
export const FORBIDDEN_ROUTE: string = "403";
export const SERVER_FAIL_ROUTE: string = "500";

//---------------------search-------------------------//

export const ASSESSMENT_ID_SEARCH: string = "assessment_id";
export const ASSESSMENT_CODE_SEARCH: string = "code";
export const USER_ID_SEARCH: string = "user_id";
export const REPORT_MENU_ITEM: string = "report_view";

export const FORBIDDEN_REASON_SEARCH: string = "forbidden_reason";
export const SELF_REGISTER_ROUTE: string = "self-register";
