import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IUser } from "@/modules/client/clients.interface";
import { IAssessment } from "@/modules/assessments/assessments.types";
import { ForbiddenReasons } from "@/modules/main/Forbidden/Forbidden";
import { InvitesHttpService } from "@/modules/candidate/services/InvitesHttpService";
import { AssessmentInviteStatus } from "@/modules/assessments/scenes/AssessmentInvites/invites.constants";
import {
  ASSESSMENT_CODE_SEARCH,
  CANDIDATE_ROUTE,
  FORBIDDEN_REASON_SEARCH,
  FORBIDDEN_ROUTE,
  NOT_FOUND_ROUTE,
} from "@/routing/AppRouter/routes.constants";
import { TestAnswerStatusEnum } from "@/shared/services/tests/tests.types";
import { getRouteWithSearchParameter } from "@/shared/utils/route/route.utils";

export interface INarrative {
  narrativeId: number;
  content: string | null;
  inviteId: number;
  createdAt: string;
  updatedAt: string;
  lastUpdatedByUser: null | IUser;
}

export interface IAssessmentInvite {
  claimedOn: string;
  startedOn?: string;
  finishedOn?: string;
  invitedOn?: string;
  createdAt: string;
  updatedAt: string;
  shareUntil?: string;
  reportUrl?: string;
  inviteId: number;
  candidateFullName?: string;
  email: string;
  code: string;
  key?: number;
  status: AssessmentInviteStatus;
  statusName: string;
  claimedBy: number;
  assessmentId: number;
  assessment: IAssessment;
  testAnswers: TTestAnswer[];
  knockoutResult?: boolean;
  netScore: number;
  grossScore: number;
  claimedByUser?: IUser;
  invitedByUser?: IUser | null;
  narrative?: INarrative;
  timerDisabled: boolean;
}

export type TTestAnswer = {
  createdAt: string;
  updatedAt: string;
  answer: TAnswer[];
  answerId: number;
  inviteId: number;
  status: TestAnswerStatusEnum;
  testId: number;
  userId: number;
};

export type TAnswer = {
  answer: boolean;
  answerKey: string;
  elementId: number;
  elementKey: string;
  scales?: string[];
};

export interface ICandidateHook {
  invite?: IAssessmentInvite;
  loading: boolean;
  error: any;
}

export function useInitializeCandidateHook(isLoadingFirebase: boolean, userId?: number): ICandidateHook {
  const navigate = useNavigate();

  const { code } = useParams();

  const [invite, setInvite] = useState<IAssessmentInvite>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      if (!code || isLoadingFirebase) {
        return;
      }

      setIsLoading(true);

      try {
        const invite = await InvitesHttpService.getAssessmentInviteByCode(String(code));

        setInvite(invite);
      } catch (e: any) {
        setError({ ...e });
        return navigate(getRouteWithSearchParameter(NOT_FOUND_ROUTE, ASSESSMENT_CODE_SEARCH, code));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [isLoadingFirebase]);

  useEffect(() => {
    (async () => {
      if (!invite) {
        return;
      }

      if (invite.status === AssessmentInviteStatus.Rescinded) {
        return navigate(
          getRouteWithSearchParameter(
            `/candidate/${FORBIDDEN_ROUTE}`,
            FORBIDDEN_REASON_SEARCH,
            ForbiddenReasons.rescindedInvite,
          ),
        );
      }

      if (!userId) {
        return;
      }

      setIsLoading(true);

      if (invite.status === AssessmentInviteStatus.Done) {
        return navigate(`/${CANDIDATE_ROUTE}`);
      }

      if (!invite.claimedBy) {
        try {
          const postedInvite: IAssessmentInvite = await InvitesHttpService.claimAssessmentInviteByCode(String(code));

          setInvite(postedInvite);
        } catch (er: any) {
          setError({ ...er });
        } finally {
          setIsLoading(false);
        }
      }

      if (invite.claimedBy && invite.claimedBy !== userId) {
        return navigate(`/candidate/${FORBIDDEN_ROUTE}?forbidden_reason=${ForbiddenReasons.acceptedInvite}`);
      }
    })();
  }, [userId, invite]);

  return { invite, loading: isLoading, error };
}
