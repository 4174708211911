import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";
import { ClientRoleDescriptions, ClientRoleEnum } from "@/modules/client/client-roles.interface";
import { useClientByIdHook } from "@/modules/client/hooks/useClientById.hook";
import { ClientHttpService } from "@/modules/client/services/client-http.service";
import { ClientAbilityContext } from "@/shared/contexts/ability.context";
import { AddNewCharacter } from "@/shared/components/AddNew/AddNewCharacter";
import { capitalizeFirstLetter, removeUnderscore } from "@/shared/utils/common.utils";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { InputType } from "@/ui-components/commonComponents/Input/Input";
import { SEO } from "@/ui-components/commonComponents/SEO/SEO";
import { CLIENT_ROUTE, NEW_ROUTE, SETTINGS_ROUTE, USER_ROUTE } from "@/routing/AppRouter/routes.constants";
import { useRouteAccessController } from "@/shared/hooks/permissions/use-route-access-controller.hook";

export default function InviteUser() {
  const { client_id: clientId } = useParams<{ client_id: string }>();
  const { client } = useClientByIdHook(Number(clientId));
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleInviteUser = async (value: { email: string; fullName: string; role: ClientRoleEnum }) => {
    try {
      setIsLoading(true);

      const response = await ClientHttpService.addUserToClient(String(clientId), value);

      if (response.status === 201) {
        notification.success({
          message: (
            <p>
              User <span style={{ fontWeight: 700 }}>{value.fullName}</span> was invited
            </p>
          ),
        });
        setTimeout(() => {
          navigate(`/${CLIENT_ROUTE}/${clientId}/${SETTINGS_ROUTE}/${USER_ROUTE}`);
        }, 3000);
      }
    } catch (e: any) {
      notification.error({ message: e.message });
    } finally {
      setIsLoading(false);
    }
  };

  const ability = useContext(ClientAbilityContext);
  const canCreateClientUser = ability.can(Action.Create, Subject.CLIENT_USER);

  useRouteAccessController({
    slug: NEW_ROUTE,
    hasPermission: canCreateClientUser,
  });

  return (
    <>
      <SEO titleParams={[client?.name, "Invite User"]} />
      <AddNewCharacter
        isEmail
        disabled={!canCreateClientUser}
        isLoading={isLoading}
        title="Invite User"
        formName="inviteUser"
        onSubmit={handleInviteUser}
        inputProps={[
          {
            name: "fullName",
            label: "Full Name",
            errorText: "Input user's full name",
            required: true,
          },
          {
            name: "email",
            label: "Email",
            errorText: "Input user email",
            required: true,
            isEmail: true,
          },
          {
            name: "role",
            label: "Select user role",
            initialValue: ClientRoleEnum.USER,
            errorText: "User role",
            inputType: InputType.select,
            selectOptions: Object.values(ClientRoleEnum).map((role) => ({
              value: role,
              description: ClientRoleDescriptions[role],
              name: removeUnderscore(role)
                .split(" ")
                .map((word) => capitalizeFirstLetter(word))
                .join(" "),
            })),
            required: true,
          },
        ]}
        buttonProps={{
          title: "Send invite",
        }}
      />
    </>
  );
}
